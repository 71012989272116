<template>
<div>
    <v-container>
        <h1 class="mb-3">予約の設定</h1>
        <p>設定を変更後「保存する」をクリックしてください</p>
        <div class=" interval_box relative-center">
            <v-card class="pa-6 mb-6 ">
                <h2 class="mb-2">予約受付時間帯</h2>
                <v-row>
                    <v-col cols="12" xl="6" lg="6" md="6" sm="6">
                        <div class="">予約受付開始</div>
                        <vue-timepicker format="HH:mm" v-model="start" hour-label="時" minute-label="分" :minute-interval="min" placeholder="開始の時間"></vue-timepicker>
                    </v-col>
                    <v-col cols="12" xl="6" lg="6" md="6" sm="6">
                        <div class="">予約受付終了</div>
                        <vue-timepicker format="HH:mm" v-model="end" hour-label="時" minute-label="分" :minute-interval="min" placeholder="終了の時間"></vue-timepicker>
                    </v-col>
                </v-row>
            </v-card>
            <v-card class="pa-6 mb-6">
                <p>問合せから何営業日後に予約可能かをご選択ください</p>
                <v-select :items="dayIntervals" item-value="id" item-text="name" v-model="dayInterval" outlined class="interval_select ml-4" />
            </v-card>
            <v-card class="pa-6 mb-6">
                <p>予約間のインターバル時間をご選択ください</p>
                <v-select :items="timeIntervals" item-value="id" item-text="name" v-model="timeInterval" outlined class="interval_select ml-4" />
            </v-card>
        </div>
        <v-btn color="#E64A19" x-large dark class="f-12 my-6" @click="submit()">保存する</v-btn>
    </v-container>
    <Snackbar ref="snack" />
</div>
</template>

<script>
import VueTimepicker from 'vue2-timepicker'
import 'vue2-timepicker/dist/VueTimepicker.css'
import Snackbar from "@/components/snackbar.vue"
import axios from "axios"
export default {

    data() {
        return {
            start: {},
            end: {},
            timeIntervals: [],
            dayIntervals: [],
            timeInterval: "",
            dayInterval: "",
            min: 0,
        }
    },
    computed: {
        User() {
            return this.$store.getters['user/user']
        },
    },
    components: {
        Snackbar,
        'vue-timepicker': VueTimepicker,
    },
    async mounted() {
        this.min = this.User.type == 1 ? 15 : 30

        if (this.User.starthh) {
            this.start.HH = ("00" + this.User.starthh).slice(-2)
            this.start.mm = ("00" + this.User.startmm).slice(-2)
            this.end.HH = ("00" + this.User.endhh).slice(-2)
            this.end.mm = ("00" + this.User.endmm).slice(-2)
        }
        this.dayInterval = this.User.dayInterval
        this.timeInterval = this.User.timeInterval
        let dayIntervals = []
        let timeIntervals = []
        dayIntervals[0] = {}
        timeIntervals[0] = {}
        dayIntervals[0].id = 0
        timeIntervals[0].id = 0
        dayIntervals[0].name = "当日"
        timeIntervals[0].name = "0 分"

        let num = 0
        if (this.User.type == 1) {
            timeIntervals[1] = {}
            timeIntervals[1].id = 30
            timeIntervals[1].name = "30 分"
            num = 1
        }

        for (let i = 1; i <= 10; i++) {
            dayIntervals[i] = {}
            timeIntervals[i + num] = {}
            dayIntervals[i].id = i
            timeIntervals[i + num].id = i
            dayIntervals[i].name = i + " 日後"
            timeIntervals[i + num].name = i + " 時間"
        }
        this.dayIntervals = dayIntervals
        this.timeIntervals = timeIntervals
    },
    methods: {
        async submit() {
            if (this.start.HH == "" || this.start.mm == "" || this.end.HH == "" || this.end.mm == "" || this.start.HH == null || this.start.mm == null || this.end.HH == null || this.end.mm == null) {
                alert("予約受付時間帯が選択されていません")
                return false
            }
            const data = {
                dayInterval: this.dayInterval,
                timeInterval: this.timeInterval,
                start: JSON.stringify(this.start),
                end: JSON.stringify(this.end),
            }

            const response = await axios.post("/user/interval/save", data)
            if (response.data.ok) {
                await this.$store.dispatch("user/save", response.data);
                this.$refs.snack.snack = true
                this.$refs.snack.message = "保存しました"
            } else {
                alert("エラーが発生しました")
            }
        }
    }
}
</script>

<style lang="scss" scoped>
@import "@/assets/sass/user/interval.scss";
</style><style>
.datepicker01 input {
    border: 1px solid #aaa;
    border-radius: .5em;
    padding: 5px 10px;
    cursor: pointer;
}
</style>
